<template>
  <div>
    <div class="introduction_bg">
      <img src="../../static/image/mobile/m_intrduce_bg.png" width="96px"/>
    </div>
    <div class="introduction_AU">
      <img src="../../static/image/mobile/m_a.png" width="96px" />
      <div class="introduction_AU_1">
        四川中峪拍卖交易服务有限责任公司简称《中峪数交》。中峪数交于2021年2月正式批复成立的交易市场，公司坐落于环境优化、人文底蕴浓厚的浙江绍兴市柯桥区；企业以产融孵化、促进商品流通内需、帮助企业去库存、刺激市场消费，独创全新批零交割商业架构，服务于全国各中小型实体生产企业。
      </div>
      <img src="../../static/image/mobile/m_c.png" width="100%" />
      <div class="introduction_AU_2">
        现在中峪数交拥有一个团结、高效、成熟的管理团队，通过科学严谨的现代化管理，使企业走上了高速发展的快车道。在“以人为本、团结敬业，自强创新”的企业精神鼓舞下，坚持“永恒品质，超然卓越”的经营宗旨，以高精品质创品牌、以诚信服务谋共赢，不断满足和超越客户的期望。
      </div>
      <img src="../../static/image/mobile/m_ap.png" width="100%" />
      <div class="introduction_AU_3">
        中峪数交的成立，是推动行业发展，推进产业创新的一种有效尝试，对相关产业多元化的有益补充。通过对交易模式的创新与优化组合，既增强了市场流动性又为企业、交易商提供了商品买卖等全方位一体化的服务，以稳定商品价格、降低企业经营成本，扩大生产企业知名度，增加生产企业销售渠道，缓解企业资金需求，从而减少企业经营风险，提升企业收益，同时，以国家政策为导向，以大众创业，万众创新为初心，服务于各生产企业的同时，为市场培育孵化更多优质的人才。
      </div>
    </div>
    <div class="introduction_CC">
      <img src="../../static/image/mobile/m_cc.png" width="135px"/>
      <div
        class="introduction_CC_item"
        v-for="item in cultureList"
        :key="item.name"
      >
        <div class="introduction_CC_item_name" v-show="item.name!='企业用人观'">{{ item.name }}</div>
        <div class="introduction_CC_item_name" v-show="item.name=='企业用人观'" style="letter-spacing: 4px;">企业<br>用人观</div>
        <div class="introduction_CC_item_value">{{ item.value }}</div>
      </div>
    </div>
    <!-- <div class="introduction_EQ">
      <img src="../../static/image/mobile/m_eq.png" width="168px"/>
      <img src="../../static/image/introduction_3.jpg" width="100%" style="margin: 30px 0 10px 0;"/>
      <img src="../../static/image/introduction_4.jpg" width="100%"/>
    </div> -->
  </div>
</template>
<script>
export default {
  name: "introduction",
  data() {
    return {
      cultureList: [
        {
          show: false,
          name: "文化理念",
          value: "至专至精 财富共赢",
        },
        {
          show: false,
          name: "合作精神",
          value: "持诚信互利共荣 以远见共谋发展",
        },
        {
          show: false,
          name: "员工理念",
          value: "专业进取锲而不舍",
        },
        {
          show: false,
          name: "员工训词",
          value: "以严谨的态度做事 用感恩的心做人",
        },
        {
          show: false,
          name: "企业用人观",
          value: "以德为首以智为本以诚为准",
        },
      ],
    };
  },
  computed: {},
  mounted() {
    window.scroll(0, 0);
  },
  methods: {
    enterImg(index) {
      this.cultureList[index].show = true;
    },
    outImg(index) {
      this.cultureList[index].show = false;
    },
  },
};
</script>
<style scoped lang="scss">
.introduction_bg {
  background: #f7f7f7;
  img {
    width: 100%;
  }
}
.introduction_AU {
  text-align: center;
  padding: 50px 18px;
  font-size: 14px;
  color: #1d1b19;
  line-height: 25px;
  letter-spacing: 1px;
  .introduction_AU_1 {
    margin: 50px 0 20px 0;
    text-align: left;
  }
  .introduction_AU_2 {
    margin: 20px 0 20px 0;
    text-align: left;
  }
  .introduction_AU_3 {
    margin: 20px 0 40px 0;
    text-align: left;
  }
}

.introduction_CC {
  text-align: center;
  padding: 50px 18px;
  background-color: #f7f7f7;
  img{
    margin-bottom: 30px;
  }
  .introduction_CC_item {
    margin-top: 10px;
    display: flex;
    .introduction_CC_item_name {
      background: url("../../static/image/mobile/m_int_cc_bg.png") no-repeat;
      background-size: cover;
      width: 100px;
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
      line-height: 34px;
      letter-spacing: 10px;
      padding-left: 4px;
    }
    .introduction_CC_item_value {
      width: 100%;
      height: 70px;
      background: #efefef;
      font-size: 14px;
      font-weight: 300;
      color: #565759;
      line-height: 70px;
      letter-spacing: 1px;
      text-align: left;
      padding-left: 18px;
    }
  }
}
.introduction_EQ{
  text-align: center;
  padding: 50px 18px;
}
</style>
